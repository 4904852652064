// This file is separate from the rest of firebase, because it contains only auth.
// This means it can be imported in contexts which do not have Window (e.g. service workers).
import 'firebase/auth';
import 'firebase/performance';
import firebase from 'firebase/app';
import { getBackendConfig } from '../utils/backends';
try {
    firebase.app();
}
catch (_a) {
    firebase.initializeApp(getBackendConfig('firebaseConfig'));
}
let authInstance = firebase.auth();
export const getAuthInstance = () => authInstance;
export const setAuthInstance = (instance) => {
    authInstance = instance;
};
export default firebase;
